import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';

function LoadingOverlay({isLoading}) {
    return (
        <Modal show={isLoading} fullscreen contentClassName="bg-none">
            <Modal.Body>
                <div
                    className="position-absolute top-50 start-50"
                    style={{transform: "translate(-50%,-50%)"}}
                >
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default LoadingOverlay;

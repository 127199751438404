import './custom.scss';

import React, {Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {createBrowserRouter, RouterProvider,} from "react-router-dom";

const ErrorPage = React.lazy(() => import("./Pages/ErrorPage"));
const Login = React.lazy(() => import("./Pages/Login"));
const LoggedInLayout = React.lazy(() => import("./Layout/LoggedInLayout"));
const Inventory = React.lazy(() => import("./Pages/Inventory"));
const Order = React.lazy(() => import("./Pages/Order"));
const Confirm = React.lazy(() => import("./Pages/Confirm"));

const router = createBrowserRouter([
    {
        path: "/",
        element: <App/>,
        errorElement: <Suspense><ErrorPage/></Suspense>,
        children: [
            {
                path: "/",
                element: <Suspense><Login/></Suspense>,
            },
            {
                element: <Suspense><LoggedInLayout/></Suspense>,
                children: [
                    {
                        path: "/inventory",
                        element: <Suspense><Inventory/></Suspense>,
                    },
                    {
                        path: "/order",
                        element: <Suspense><Order/></Suspense>,
                    },
                    {
                        path: "/confirm",
                        element: <Suspense><Confirm/></Suspense>,
                    },
                ],
            },
        ]
    },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <RouterProvider router={router}/>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

let interceptor = null;

export function addAccessTokenToAxios(accessToken, userStore, set) {
  instance.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;

  // add an interceptor to refresh token when it expires
  interceptor = instance.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error.response && error.response.status === 401) {
        await userStore.logout();
      }

      return Promise.reject(error);
    }
  );
}

export function removeAccessTokenFromAxios() {
  delete instance.defaults.headers.common["Authorization"];

  // remove interceptors
  instance.interceptors.response.eject(interceptor);
}

export default instance;

import { create } from "zustand";
import axios, {addAccessTokenToAxios, removeAccessTokenFromAxios} from "../lib/axios";

export const useUserStore = create((set) => ({
  isLoading: true,
  accessToken: null,
  refreshToken: null,
  user: {
    username: "",
    email: "",
    phone: "",
    accessPermissions: [],
    clinic: "",
  },
  async initialize() {
    const accessToken = localStorage.getItem("accessToken");

    if (accessToken) {
      set({ accessToken });
      addAccessTokenToAxios(accessToken, this, set);
      await this.getUserData();
    } else {
      set({
        isLoading: false,
      });
    }
  },
  async login(username, password) {
    const data = {
      username: username,
      password: password,
    };

    try {
      const response = await axios.post("/login", data);
      const { token, refresh_token } = response.data;

      if (!token || !refresh_token) {
        return { success: false, error: "Invalid response data" };
      }

      set({
        accessToken: token,
        refreshToken: refresh_token,
      });

      localStorage.setItem("accessToken", token);
      localStorage.setItem("refreshToken", refresh_token);

      addAccessTokenToAxios(token, this, set);

      await this.getUserData();

      return { success: true };
    } catch (error) {
      return { success: false, error };
    }
  },
  async getUserData() {
    try {
      const response = await axios.get("/me");
      const userData = response.data["hydra:member"][0];
      const updatedUser = {
        username: userData.username,
        email: userData.email,
        phone: userData.phone,
        accessPermissions: userData.accessPermissions,
        clinic: userData.clinic,
      };

      set({
        user: updatedUser,
        isLoading: false,
      });
    } catch (error) {
      console.error("Request Error:", error);
    }
  },
  async refreshAccessToken() {
    try {
      addAccessTokenToAxios(this.accessToken, this, set);

      const data = {
        refresh_token: this.refreshToken,
      };
      const response = await axios.post(`/token/refresh`, data);
      const { token } = response.data;

      set({ accessToken: token });
      addAccessTokenToAxios(token, this, set);

      localStorage.setItem("accessToken", token);
    } catch (error) {
      console.error("Refresh Token Error:", error.message);
    }
  },
  async logout() {
    set({
      accessToken: null,
      refreshToken: null,
      user: {
        username: "",
        email: "",
        phone: "",
        accessPermissions: [],
        clinic: "",
      },
    });

    localStorage.removeItem("accessToken");

    removeAccessTokenFromAxios();
  },
}));

import {Outlet, useNavigate} from "react-router-dom";
import {useUserStore} from "./stores/user";
import {useCallback, useEffect} from "react";
import LoadingOverlay from "./Components/LoadingOverlay";

function App() {
    const callback = useCallback(() => {
        useUserStore.getState().initialize();
    }, []);

    useEffect(() => {
        callback();
    }, [callback]);


    const navigate = useNavigate();
    const user = useUserStore((state) => state.user);
    const isLoading = useUserStore((state) => state.isLoading);

    useEffect(() => {
        if (Object.values(user.accessPermissions).includes("Inventory")) {
            navigate("/inventory");
        } else if (Object.values(user.accessPermissions).includes("Order")) {
            navigate("/order");
        } else {
            navigate("/");
        }
    }, [user, navigate]);

    return (
        <>
            <LoadingOverlay isLoading={isLoading}/>
            <Outlet/>
        </>
    );
}

export default App;
